import { useReportError } from '/machinery/ReportError'
import { useLocation } from '@kaliber/routing'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SearchContextProvider } from '/machinery/SearchContext'

import { ArticlePageDefaultWithoutContext, ArticlePageSnackablesWithoutContext, ArticlePageGedichtWithoutContext, ArticlePageBorderedWithoutContext, ArticlePageMemberOfferWithoutContext, ArticlePageKaliberDefaultWithoutContext } from './article-types/ArticlePage'

export default function ArticlePageWithoutContext({ doc, cover, relatedArticles }) {
  const client = new QueryClient()
  const reportError = useReportError()
  const location = useLocation()

  const PageType = getPageToRender({ template: doc.metadata.template, reportError, location })

  return (
    <SearchContextProvider>
      <QueryClientProvider {...{ client }}>
        <PageType {...{ doc, cover, relatedArticles }} />
      </QueryClientProvider>
    </SearchContextProvider>
  )
}

function getPageToRender({ template, location, reportError }) {
  switch (template) {
    case 'gedicht':
      return ArticlePageGedichtWithoutContext
    case 'snackables':
      return ArticlePageDefaultWithoutContext
    case 'single_snackable':
    case 'single_snackable_content':
      return ArticlePageBorderedWithoutContext
    case 'kaliber-snackables':
      return ArticlePageSnackablesWithoutContext
    case 'kaliber-member_offer':
      return ArticlePageMemberOfferWithoutContext
    case 'member_offer': //When people visit canonical pages with the ID of a single member offer
      return ArticlePageDefaultWithoutContext
    case 'research':
      return ArticlePageDefaultWithoutContext
    case 'kaliber_research':
      return ArticlePageKaliberDefaultWithoutContext
    case 'kaliber_article':
      return ArticlePageKaliberDefaultWithoutContext
    case 'kunstcollectie':
      return ArticlePageDefaultWithoutContext
    case 'default':
    default:
      if (template !== 'default') reportError(new Error(`Could not find template '${template}'. Error occurred on ${location.pathname}`))
      return ArticlePageDefaultWithoutContext
  }
}
